var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fc-config-component table-component" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-breadcrumb" },
        [
          _c(
            "a-breadcrumb",
            [
              _c(
                "a-breadcrumb-item",
                [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                1
              ),
              _c("a-breadcrumb-item", [_vm._v("费用设置")]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _c(
          "jtl-button",
          { attrs: { type: "primary", "click-prop": _vm.saveClick } },
          [_vm._v("保存")]
        ),
        _c("br"),
        _c("a-card", { staticClass: "food-fee-wrapper" }, [
          _c(
            "div",
            {
              staticClass: "card-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_c("span", { staticClass: "inline-block" }, [_vm._v("餐费设置")])]
          ),
          _c(
            "div",
            { staticClass: "fee-input-wrapper" },
            [
              _vm._v("早餐 "),
              _c("a-input", {
                staticClass: "fee-input",
                attrs: { placeholder: "请输入早餐费用", suffix: "￥" },
                model: {
                  value: _vm.tableMoney.breakfast,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableMoney, "breakfast", _vm._n($$v))
                  },
                  expression: "tableMoney.breakfast",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "fee-input-wrapper" },
            [
              _vm._v("午餐 "),
              _c("a-input", {
                staticClass: "fee-input",
                attrs: { placeholder: "请输入午餐费用", suffix: "￥" },
                model: {
                  value: _vm.tableMoney.lunch,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableMoney, "lunch", _vm._n($$v))
                  },
                  expression: "tableMoney.lunch",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "fee-input-wrapper" },
            [
              _vm._v("晚餐 "),
              _c("a-input", {
                staticClass: "fee-input",
                attrs: { placeholder: "请输入晚餐费用", suffix: "￥" },
                model: {
                  value: _vm.tableMoney.dinner,
                  callback: function ($$v) {
                    _vm.$set(_vm.tableMoney, "dinner", _vm._n($$v))
                  },
                  expression: "tableMoney.dinner",
                },
              }),
            ],
            1
          ),
        ]),
        _c("br"),
        _c(
          "a-card",
          [
            _c(
              "div",
              {
                staticClass: "card-title",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c("span", { staticClass: "inline-block" }, [
                  _vm._v("住宿费设置"),
                ]),
              ]
            ),
            _c(
              "a-row",
              { staticClass: "room-fee-wrapper", attrs: { gutter: 16 } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  _vm._l(_vm.Column1Setting, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "fee-input-wrapper" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fee-label",
                            attrs: { title: item.locationName },
                          },
                          [_vm._v(_vm._s(item.locationName))]
                        ),
                        _c("a-input", {
                          staticClass: "fee-input",
                          attrs: { placeholder: "A栋住宿费用", suffix: "￥" },
                          model: {
                            value: item.cost,
                            callback: function ($$v) {
                              _vm.$set(item, "cost", _vm._n($$v))
                            },
                            expression: "item.cost",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  _vm._l(_vm.Column2Setting, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "fee-input-wrapper" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fee-label",
                            attrs: { title: item.locationName },
                          },
                          [_vm._v(_vm._s(item.locationName))]
                        ),
                        _c("a-input", {
                          staticClass: "fee-input",
                          attrs: { placeholder: "A栋住宿费用", suffix: "￥" },
                          model: {
                            value: item.cost,
                            callback: function ($$v) {
                              _vm.$set(item, "cost", _vm._n($$v))
                            },
                            expression: "item.cost",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  _vm._l(_vm.Column3Setting, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "fee-input-wrapper" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fee-label",
                            attrs: { title: item.locationName },
                          },
                          [_vm._v(_vm._s(item.locationName))]
                        ),
                        _c("a-input", {
                          staticClass: "fee-input",
                          attrs: { placeholder: "A栋住宿费用", suffix: "￥" },
                          model: {
                            value: item.cost,
                            callback: function ($$v) {
                              _vm.$set(item, "cost", _vm._n($$v))
                            },
                            expression: "item.cost",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "a-col",
                  { attrs: { span: 6 } },
                  _vm._l(_vm.Column4Setting, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "fee-input-wrapper" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fee-label",
                            attrs: { title: item.locationName },
                          },
                          [_vm._v(_vm._s(item.locationName))]
                        ),
                        _c("a-input", {
                          staticClass: "fee-input",
                          attrs: { placeholder: "A栋住宿费用", suffix: "￥" },
                          model: {
                            value: item.cost,
                            callback: function ($$v) {
                              _vm.$set(item, "cost", _vm._n($$v))
                            },
                            expression: "item.cost",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }