




















































































import { Component } from 'vue-property-decorator';
import FcUserService, { feeSettingQuery, feeSettingSave } from '@common-src/service/fc-user';
import { BaseComponent } from '@common-src/mixins/base-component';

@Component
export default class FcConfigComponent extends BaseComponent {
    accommodationCost: any = null;
    tableMoney: any = {};
    colNum: number = 15;
    created() {
        feeSettingQuery().then(res => {
            this.tableMoney = res.tableMoney || {};
            this.accommodationCost = res.accommodationCost;
            if (this.accommodationCost && this.accommodationCost.length > 0) {
                this.colNum = Math.ceil(this.accommodationCost.length / 4);
            }
        });
    }

    get Column1Setting() {
        // return _.filter(this.accommodationCost, item => ['A栋', 'B栋'].indexOf(item.locationName) > -1);
        return _.take(this.accommodationCost, this.colNum);
    }
    get Column2Setting() {
        // return _.filter(this.accommodationCost, item => item.locationName?.indexOf('方舱') > -1);
        return _.filter(this.accommodationCost, (item, index: number) => index >= this.colNum && index < this.colNum * 2);
    }
    get Column3Setting() {
        // return _.filter(this.accommodationCost, item => item.locationName?.indexOf('东区') > -1);
        return _.filter(this.accommodationCost, (item, index: number) => index >= this.colNum * 2 && index < this.colNum * 3);
    }
    get Column4Setting() {
        // return _.filter(this.accommodationCost, item => item.locationName?.indexOf('西区') > -1);
        return _.filter(this.accommodationCost, (item, index: number) => index >= this.colNum * 3 && index < this.colNum * 4);
    }

    saveClick() {
        const params = {
            tableMoney: this.tableMoney,
            accommodationCost: this.accommodationCost
        };
        return feeSettingSave(params).then(res => {
            this.showMessageSuccess('费用设置成功');
        });
    }
}

